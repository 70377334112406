<template>
    <div class="cancelled-schedule-component">
        <appointment-thread-component
            v-bind="cancelledProps"
            :load-more-label="loadMoreLabel"
            @get-appointment-uuid="$emit('get-appointment-uuid', $event)"
            @set-tab-name="$emit('set-tab-name', $event)"
        />
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
    AppointmentThreadComponent,
} from '@CommonComponents';
import { AccountType } from '@Utilities/enum';
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";

export default {
    name: 'CancelledScheduleComponent',
    components: {
        AppointmentThreadComponent,
    },
    props: {
        tabName: { type: String, default: '' },
        loadMoreLabel: { type: String, default: 'More appointments' },
    },
    emits: [ "get-appointment-uuid", "set-tab-name" ],
    setup(props) {
        const store = useStore();

        // DONE APPOINTMENTS DATA
        const appointments = computed(() => {
            return store.getters['CommonAppointment/cancelledAppointmentList']
        });

        // DONE APPOINTMENT BINDED PROPS
        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const cancelledProps = ref({
            title: userType.value === AccountType.Medrep ? "Cancelled Appointments" : "",
            type: "cancelled",
            tabName: props.tabName,
            appointments: appointments,
            emptyTitle: "No cancelled appointments",
            uniqueId: "cancelled-schedule-read-more",
            hasInfiniteLoading: userType.value === AccountType.Medrep,
            showStatus: true,
        })

        return {
            cancelledProps,
        }
    }
}
</script>

<style lang="scss">
.cancelled-schedule-component {
}
</style>